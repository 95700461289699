import {
  UilInstagram,
  UilTwitterAlt,
  UilFacebookF,
  UilYoutube,
  UilWhatsapp,
  UilPhone,
} from "@iconscout/react-unicons";
import Image from "next/image";
import FooterLogos from "/public/logos.png";
import Link from "next/link";
import React from "react";
import ScrollToTopButton from "./scrollToTop";
const footer = () => {
  return (
    <>
      <div className="container mx-auto px-4 ">
        <div className="pt-10  md:grid md:grid-cols-3">
          <div className="col-span-2">
            <div className="text-2xl font-semibold leading-relaxed md:text-3xl lg:text-4xl text-center md:text-left mb-4">
              {" "}
              Bizi her zaman arayabilirsiniz 👋
            </div>
            <p className="text-sm font-light md:text-base  text-center md:text-left ">
              İhtiyaç duyduğunuz her anınızda bizleri arayabilirsiniz, destek
              ekibimiz 7/24 sizlerle.
            </p>
          </div>
          <div className="">
            <a
              href="tel:08503466600"
              className="block font-semibold text-3xl pt-0 text-center md:text-right"
            >
              0850 <span className="font-bold">346 66 00</span>
            </a>
            <a
              href="mailto:info@eticex.com"
              className="text-gray-600 text-lg font-medium text-center md:text-right block leading-10"
            >
              info@eticex.com
            </a>
          </div>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-4 py-10 md:py-20">
          <div>
            <h4 className="font-semibold leading-10 md:leading:10">
              E-Ticaret Sistemleri
            </h4>
            <ul className="text-sm leading-8 text-gray-600">
              <li>
                <Link href="/hakkimizda.html" className="hover:text-orange-600">
                  Hakkımızda
                </Link>
              </li>
              {/* <li><Link href="#" className='hover:text-orange-600'>Basında Biz</Link></li> */}
              <li>
                <Link
                  href="/referanslar.html"
                  className="hover:text-orange-600"
                >
                  Referanslarımız
                </Link>
              </li>
              <li>
                <Link href="/blog" className="hover:text-orange-600">
                  Blog
                </Link>
              </li>
              {/* <li><Link href="#" className='hover:text-orange-600'>İş Ortaklarımız ???</Link></li> */}
              <li>
                <Link href="/bayilik.html" className="hover:text-orange-600">
                  Bayilik
                </Link>
              </li>
              {/* <li><Link href="#" className='hover:text-orange-600'>İnsan Kaynakları</Link></li> */}
              <li>
                <Link
                  href="/banka-hesap-bilgileri.html"
                  className="hover:text-orange-600"
                >
                  Banka Hesap Bilgileri
                </Link>
              </li>
              <li>
                <Link href="/iletisim.html" className="hover:text-orange-600">
                  İletişim
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h4 className="font-semibold leading-10">Ürün ve Hizmetlerimiz</h4>
            <ul className="text-sm leading-8 text-gray-600">
              <li>
                <Link
                  href="/e-ticaret-paketleri.html"
                  className="hover:text-orange-600"
                >
                  E-Ticaret Paketleri
                </Link>
              </li>
              <li>
                <Link
                  href="/aylik-e-ticaret-paketleri.html"
                  className="hover:text-orange-600"
                >
                  Aylık E-Ticaret Paketleri
                </Link>
              </li>
              <li>
                <Link
                  href="/aylik-e-ticaret-paketleri.html"
                  className="hover:text-orange-600"
                >
                  E-Ticaret Paketini Oluştur
                </Link>
              </li>
              <li>
                <Link href="/e-ihracat.html" className="hover:text-orange-600">
                  E-İhracat
                </Link>
              </li>
              <li>
                <Link
                  href="/entegrasyonlar.html"
                  className="hover:text-orange-600"
                >
                  Entegrasyonlar
                </Link>
              </li>
              <li>
                <Link
                  href="/ozel-tasarim-hizmeti.html"
                  className="hover:text-orange-600"
                >
                  Özel Tasarım Hizmetleri
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h4 className="font-semibold leading-10">Sözleşmelerimiz</h4>
            <ul className="text-sm leading-8 text-gray-600">
              <li>
                <Link
                  href="/gizlilik-sozlesmesi.html"
                  className="hover:text-orange-600"
                >
                  Gizlilik Sözleşmesi
                </Link>
              </li>
              <li>
                <Link
                  href="/mesafeli-satis-sozlesmesi.html"
                  className="hover:text-orange-600"
                >
                  Mesafeli Satış Sözleşmesi
                </Link>
              </li>
              <li>
                <Link
                  href="/cerez-politikasi.html"
                  className="hover:text-orange-600"
                >
                  Çerez Politikası
                </Link>
              </li>
              <li>
                <Link href="/kvkk.html" className="hover:text-orange-600">
                  K.V.K.K.
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h4 className="font-semibold leading-10">İletişim</h4>
            <span className="text-gray-600 text-xs leading-6 block">
              Adres,
            </span>
            <span className="block text-xs leading-5">
              Kozyatağı, Değirmen Sok. Duranbey İş Merkezi <br /> No: 13 D: 5,
              34742 Kadıköy/İstanbul
            </span>

            <Link
              href="https://www.instagram.com/eticexcom"
              target="_blank"
              rel="noreferrer"
            >
              <UilInstagram
                className="inline mt-3 mr-3 w-5"
                aria-label="Eticex instagram"
              />
            </Link>
            <Link
              href="https://twitter.com/eticex"
              target="_blank"
              rel="noreferrer"
            >
              <UilTwitterAlt
                className="inline mt-3 mr-3 w-5"
                aria-label="Eticex Twitter"
              />
            </Link>
            <Link
              href="https://m.facebook.com/eticexcom"
              target="_blank"
              rel="noreferrer"
            >
              <UilFacebookF
                className="inline mt-3 mr-3 w-5"
                aria-label="Eticex Facebook"
              />
            </Link>
            <Link
              href="https://www.youtube.com/channel/UCmmtC1Ip2_7Lti3GedK7cvQ"
              target="_blank"
              rel="noreferrer"
            >
              <UilYoutube
                className="inline mt-3 mr-3 w-5"
                aria-label="Eticex Youtube"
              />
            </Link>
          </div>
        </div>

        <div className="border-t py-3 leading-10 text-xs grid grid-cols-1 md:grid-cols-2 ">
          <div className="text-center md:text-left">
            Eticex bir Markasis Bilişim A.Ş. iştirakidir.
          </div>
          <div className="text-center md:text-right">
            <Image
              src={FooterLogos}
              alt=""
              width="275"
              height="40"
              className="mt-4 md:mt-0 mx-auto md:float-right w-[165px] h-[24px] md:w-[275px] md:h-[40px]"
            />
          </div>
        </div>
      </div>

      <div className="bg-transparent md:shadow-none left-0 fixed lg:bottom-[50px] bottom-[0px] py-2 px-2">
        <div className="flex justify-between">
          <a
            href="tel:08503466600"
            className=" border shadow rounded-full px-2 py-1 bg-white ml-0 md:ml-12   cursor-pointer font-semibold"
          >
            <UilPhone className="md:h-8 md:w-8 text-green-500 inline" />
            <span className="text-xs ml-2 pr-1">0850 346 66 00</span>
          </a>
        </div>
      </div>
      <div className="bg-transparent md:shadow-none right-0 fixed lg:bottom-[50px] bottom-[0px] py-2 px-2">
        <div className="flex justify-between">
          <a
            href="https://wa.me/908503466600"
            className=" border shadow l rounded-full px-2 py-1 bg-white mr-0 md:mr-12 cursor-pointer font-semibold"
          >
            <UilWhatsapp className="md:h-8 md:w-8 text-green-500 inline" />
            <span className="text-xs ml-2 pr-1">WhatsApp</span>
          </a>
        </div>
      </div>
      <ScrollToTopButton />
    </>
  );
};

export default footer;
