import { useState, useEffect } from 'react';
import { UilAngleUp } from "@iconscout/react-unicons";

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsVisible(window.scrollY > 300); // 300px kaydırma durumunda buton görünür
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <button
      onClick={scrollToTop}
      name='moveTop'
      aria-label="moveTop"
      className={`fixed bottom-16 right-4 p-1 bg-blue-500 text-white rounded-full shadow-lg transition-opacity duration-300 ${isVisible ? 'opacity-100' : 'opacity-0'
        }`}
    >
      <UilAngleUp size={20} />
    </button>
  );
};

export default ScrollToTopButton;